.taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 300;
  background: linear-gradient(70deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
  color: rgba(255, 255, 255, .87);

  #start-menu {
    position: absolute;
    bottom: 60px;
    left: 0;
    background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
    color: rgba(255, 255, 255, .87);
    width: 300px;
    display: none;
    z-index: 99;
    border-radius: 0 5px 0 0;
  }

  li {
    text-align: center;
  }

  a {
    text-decoration: none;
    color: rgba(255,255,255, 0.8);
  }

  #start-menu ul {
    position: relative;
    width: 70%;
    padding: 10px;
    display: block;
  }

  #settings {
    position: absolute;
    width: 30%;
    bottom: 0;
    right: 0;
  }

  #settings ul {
    position: relative;
    padding: 10px;
    display: block;
    margin-bottom: 16px;
  }

  .settings-item {
    display: block;
    padding: 10px;
    border-radius: 3px;
    margin: 5px 0 0 5px;
  }

  .start-menu-item {
    display: block;
    padding: 15px;
    background-color: rgba(255, 255, 255, .25);
    border-radius: 3px;
    margin-top: 5px;
    text-align: left;
    font-size: 12pt;
    cursor: pointer;
    i {
      margin-right: 10px;
    }

    &.disabled {
      color: rgba(29, 51, 71, 0.4);
      cursor: not-allowed !important;
    }
  }

  #home-button {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin: 6px 10px;
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, .25);
    border-radius: 3px;
    cursor: pointer;
  }

  .start-menu-item:hover,
  .settings-item:hover,
  #home-button:hover,
  #home-btn:hover,
  #go-btn:hover {
    background-color: rgba(255, 255, 255, .2);
    cursor: pointer;

    &.disabled {
      background-color: rgba(255, 255, 255, .5);
      cursor: default;

    }
  }

  .storyBtn {
    position: relative;
    display: inline-block;
    font-size: 12pt;
    margin: 0 5px;
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, .25);
    border-radius: 3px;
    cursor: pointer;
  }

  .taskbar-tools {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 3px;
    position: relative;
    display: block;
    float: right;
    margin: 5px 10px;
    padding: 15px;
  }

  #time {
    position: relative;
    display: block;
    float: right;
    margin-right: 10px;
    cursor: default;
    font-size: 11pt;
  }

  #battery {
    position: relative;
    display: block;
    float: right;
    margin-left: 30px;
  }

  #name {
    position: relative;
    display: block;
    float: right;
    margin-left: 10px;
  }

  #battery:hover {
    color: green;
  }

  li>.fa-power-off:hover {
    color: red;
  }

  #wifi {
    position: relative;
    display: block;
    float: right;
    margin: 0 20px;
  }

  #wifi:hover {
    color: #00bfff;
  }

  .unavailable {
    color: #890000;
  }
}
