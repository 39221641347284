.loginPage {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .languagePicker {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    background: white;
    border-bottom-left-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    img {
      flex: 0 auto;
      width: 20px;
      margin: 0 5px;
    }
  }

  .loginBox {
    margin-top: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 4;
    width: 270px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255,0.70) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;

    .loginHeader {
      display: flex;
      color: white;

      cursor:grab;
      height: 36px;
      width: 100%;
      margin-bottom: 12px;
      line-height: 36px;
      background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
      align-items: center;
      border-radius: 5px 5px 0 0;

      h3 {
        font-family: 'Courier Prime', 'Courier New', Serif;
        margin-left: 10px;
        font-size: 13px;
      }

      .closeBtn {
        width: 14px;
        margin-left: auto;
        margin-right: 10px;
      }
    }

    p {
      margin-top: auto;
      text-align: left;
      width: 240px;
    }
    .passwordInput {
      font-family: 'Courier Prime', 'Courier New', Serif;
      margin-top: 15px;
      width: 205px;
      height: 45px;
      border: 1px solid #E5E5E5;
      border-radius: 5px;
      background: rgba(255,255,255,0.2);
      padding-left: 31px;
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgba(29, 51, 71, 1);
      }
    }

    .loginBtn {
      font-family: 'Courier Prime', 'Courier New', Serif;
      margin-top: 20px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      width: 240px;
      height: 45px;
      background: linear-gradient(45deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
      border-radius: 5px;
      color: white;
      border: none;
      span {
        margin-left: 31px;
      }
    }
  }
}
