@import "~react-image-gallery/styles/scss/image-gallery.scss";

.storyPage {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  .fileOpen {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.46);
    backdrop-filter: blur(6px);

    .downloadBox {
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      width: 300px;
      height: 250px;
      z-index: 1;

      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 5px;

      .downloadHeader {
        font-family: 'Courier Prime', 'Courier New', Serif;
        display: flex;
        color: white;
        height: 36px;
        width: 100%;
        line-height: 36px;
        background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
        border-radius: 5px 5px 0 0;

        align-items: center;

        h3 {
          margin-left: 10px;
          font-size: 13px;
        }
      }

      .downloadBody {
        width: 100%;
        min-height: 200px;
        text-align: left;

        display: flex;
        flex-direction: column;
        align-items: center;

        .showCollection {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        p {
          margin-left: 10px;
          text-align: left;
        }

        .nextBtn {
          font-family: 'Courier Prime', 'Courier New', Serif;
          margin-top: 20px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          width: 195px;
          height: 45px;
          color: rgba(29, 51, 71, 1);
          border: 1px solid rgba(29, 51, 71, 1);
          border-radius: 5px;
          cursor: pointer;
          span {
            margin-left: 31px;
          }
        }

        a {
          text-decoration: none;
        }

        .downloadBtn {
          font-family: 'Courier Prime', 'Courier New', Serif;
          border:none;
          display: flex;
          align-items: center;
          width: 195px;
          height: 45px;
          background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
          border-radius: 5px;
          color: white;
          cursor: pointer;
          span {
            margin-left: 31px;
          }
        }
      }
    }
  }


  .storyBox, .welcomeBox {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    position: relative;

    width: 480px;
    max-width: 600px;
    min-width: 300px;
    margin: 5px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255,0.85) 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: start;

    .storyHeader {
      font-family: 'Courier Prime', 'Courier New', Serif;
      display: flex;
      color: white;

      cursor:grab;
      height: 26px;
      width: 100%;
      line-height: 36px;
      background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
      border-radius: 5px 5px 0 0;
      align-items: center;

      h3 {
        margin-left: 10px;
        font-size: 12px;
      }

      .closeBtn {
        cursor: pointer;
        width: 14px;
        margin-left: auto;
        margin-right: 10px;
      }
    }

    .storyBody {
      width: 100%;
      text-align:left;
      overflow: scroll;

      p, h1, h2, h3 {
        margin-left: 10px;
        margin-right: 10px;
        text-align: left;
      }

      h1,h2,h3 {
        font-family: 'Courier Prime', 'Courier New', Serif;
      }
    }

    .decodeBar {
      height: 49px;
      background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      color: white;
      border-radius: 0 0 5px 5px;
      .inputForm {
        display: flex;
        margin-left: auto;
        align-items: center;
      }

      p {
        font-family: 'Courier Prime', 'Courier New', Serif;
        margin-left: 10px;
      }
      .codeInput {
        font-family: 'Courier Prime', 'Courier New', Serif;
        width: 136px;
        height: 29px;
        background: rgba(255,255,255,0.2);
        border-radius: 5px;
        border: 0px;
        padding-left: 10px;
        color: white;
        margin-left: auto;
        &::placeholder {
          color: rgba(0,0,0,0.5);
        }
      }

      .submitBtn {
        font-family: 'Courier Prime', 'Courier New', Serif;
        display: flex;
        align-items: center;
        width: 140px;
        height: 33px;
        color: rgba(29, 51, 71, 1);
        background: white;
        border-radius: 5px;
        border: none;
        font-size: 12px;
        margin-right: 10px;
        margin-left: 9px;
        cursor: pointer;
        span {
          margin-left: 31px;
        }
      }
    }
  }

  .storyOpen {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.46);
    backdrop-filter: blur(6px);

    .downloadBox {
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      width: 300px;
      height: 280px;
      z-index: 1;

      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;

      border-radius: 5px;

      .downloadHeader {
        font-family: 'Courier Prime', 'Courier New', Serif;
        display: flex;
        color: white;
        height: 36px;
        width: 100%;
        line-height: 36px;
        background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
        border-radius: 5px 5px 0 0;

        align-items: center;

        h3 {
          margin-left: 10px;
          font-size: 13px;
        }
      }

      .downloadBody {
        width: 100%;
        min-height: 300px;
        text-align: left;

        display: flex;
        flex-direction: column;
        align-items: center;

        .showCollection {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        p {
          margin-left: 10px;
          text-align: left;
        }

        .nextBtn {
          font-family: 'Courier Prime', 'Courier New', Serif;
          margin-top: 20px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          width: 195px;
          height: 45px;
          color: rgba(29, 51, 71, 1);
          border: 1px solid rgba(29, 51, 71, 1);
          border-radius: 5px;
          cursor: pointer;
          span {
            margin-left: 31px;
          }
        }

        a {
          text-decoration: none;
          margin-top: 30px;
        }

        .downloadBtn {
          font-family: 'Courier Prime', 'Courier New', Serif;
          border:none;
          display: flex;
          align-items: center;
          width: 195px;
          height: 45px;
          background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);
          border-radius: 5px;
          color: white;
          cursor: pointer;
          span {
            margin-left: 31px;
          }
        }
      }
    }
  }

  .imageBox {
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    width: calc(50% - 10px);
    max-width: 600px;
    min-width: 300px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    align-self: start;

    img {
      width: 100%;
      border-radius: 0 0 5px 5px;
    }
    .imageHeader {
      font-family: 'Courier Prime', 'Courier New', Serif;
      display: flex;
      color: white;

      cursor: grab;
      height: 26px;
      width: 100%;
      line-height: 36px;
      background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);

      align-items: center;
      border-radius: 5px 5px 0 0;

      h3 {
        margin-left: 10px;
        font-size: 12px;
      }

      .closeBtn {
        cursor: pointer;
        width: 14px;
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .imageBody {
      width: 100%;
      height: 100%;
      background-size: cover;

      .image-gallery-image {
        background: #FFFFFF;
      }

      .image-gallery-thumbnails-wrapper {
        /*background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);*/
      }

      .image-gallery-svg {
        width: 25px;
      }
    }

    .imageText{
      padding: 5px 10px;
      background: #FFFFFF;
      border-radius: 0px 0px 5px 5px;
      margin-top: -20px;
    }
  }
  .videoBox {
    position: relative;
    margin: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    width: calc(50% - 10px);
    max-width: 600px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    align-self: start;

    .videoBody iframe, .videoBody object, .videoBody embed  {
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border: 0;
    }
    .videoHeader {
      font-family: 'Courier Prime', 'Courier New', Serif;
      display: flex;
      color: white;
      cursor: grab;
      height: 26px;
      width: 100%;
      line-height: 10px;
      background: linear-gradient(90deg, rgba(29, 51, 71, 1) 0%, rgba(17, 64, 107,1) 100%);

      align-items: center;
      border-radius: 5px 5px 0 0;

      h3 {
        margin-left: 10px;
        font-size: 12px;
      }

      .closeBtn {
        cursor: pointer;
        width: 14px;
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .videoBody {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      margin-bottom: 20px;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    .videoText{
      padding: 5px 10px;
      background: #FFFFFF;
      border-radius: 0px 0px 5px 5px;
      margin-top: -20px;
    }
  }
}
