.App {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: black;
  font-family: "Ubuntu";

  p {
    font-size: 11pt;
  }

  a {}

  .connectivityBox {
    width: 114px;
    height: 114px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
    background: #FFFFFF;
    border-top-right-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: -20px;
      margin-bottom: -20px;
      width: 60px;
      height: 60px;
    }
  }

  .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contentWrapper {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    .bgLogoStory {
      background-image: url("/back.png");
      background-size: cover;
      background-position: center;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
    }

    .bgLogoLogin {
      background-image: url("/back.png");
      background-size: cover;
      background-position: center;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
      overflow: hidden;
    }

    .loadingContainer {
      position: fixed;
      z-index: 10;
      background-color: rgba(32, 32, 32, 0.74);;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        margin: 20px;

        .lds-default {
          display: inline-block;
          position: relative;
          width: 160px;
          height: 160px;
        }

        .lds-default div {
          position: absolute;
          width: 18px;
          height: 18px;
          background: #c99000;
          border-radius: 50%;
          animation: lds-default 1.2s linear infinite;
        }

        .lds-default div:nth-child(1) {
          animation-delay: 0s;
          top: 74px;
          left: 132px;
        }

        .lds-default div:nth-child(2) {
          animation-delay: -0.1s;
          top: 44px;
          left: 124px;
        }

        .lds-default div:nth-child(3) {
          animation-delay: -0.2s;
          top: 22px;
          left: 104px;
        }

        .lds-default div:nth-child(4) {
          animation-delay: -0.3s;
          top: 14px;
          left: 74px;
        }

        .lds-default div:nth-child(5) {
          animation-delay: -0.4s;
          top: 22px;
          left: 44px;
        }

        .lds-default div:nth-child(6) {
          animation-delay: -0.5s;
          top: 44px;
          left: 22px;
        }

        .lds-default div:nth-child(7) {
          animation-delay: -0.6s;
          top: 74px;
          left: 14px;
        }

        .lds-default div:nth-child(8) {
          animation-delay: -0.7s;
          top: 104px;
          left: 22px;
        }

        .lds-default div:nth-child(9) {
          animation-delay: -0.8s;
          top: 124px;
          left: 44px;
        }

        .lds-default div:nth-child(10) {
          animation-delay: -0.9s;
          top: 132px;
          left: 74px;
        }

        .lds-default div:nth-child(11) {
          animation-delay: -1s;
          top: 124px;
          left: 104px;
        }

        .lds-default div:nth-child(12) {
          animation-delay: -1.1s;
          top: 104px;
          left: 124px;
        }

        @keyframes lds-default {
          0%, 20%, 80%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.5);
          }
        }
      }
    }

    .overlay {
      width: 100%;
      height: 100%;

      .logo {
        pointer-events: none;
        width: 100px;
        position: absolute;
        margin: auto;
        left: 40px;
        top: 20px;
      }
    }
  }
}
